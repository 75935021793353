import * as React from "react";
import { useState } from "react";
import CardFlip from "react-card-flip";
import { ChevronRightCircle } from "@styled-icons/boxicons-regular/ChevronRightCircle";

const FlipCard = (props) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const flipBack = (e) => {
    setIsFlipped(true);
  };
  const flipFront = (e) => {
    setIsFlipped(false);
  };

  const number = props.number;
  let printNumber;
  if (number) {
    printNumber = props.number;
  }
  const title = props.title;
  let printTitle;
  if (title) {
    printTitle = props.title;
  }
  const subtitle = props.subtitle;
  let printSubtitle;
  if (subtitle) {
    printSubtitle = props.subtitle;
  }
  const text1 = props.text1;
  let printText1;
  if (text1) {
    printText1 = props.text1;
  }
  const text2 = props.text2;
  let printText2;
  if (text2) {
    printText2 = props.text2;
  }
  const url = props.url;
  let printUrl;
  if (url) {
    printUrl = props.url;
  }
  const image = props.image;

  return (
    <CardFlip
      isFlipped={isFlipped}
      flipDirection="horizontal"
      flipSpeedBackToFront={0.5}>
      <div
        className="portfolio-card card card-front"
        onClick={flipBack}
        onMouseEnter={flipBack}>
        <div className="card-title flex flex-row items-end p-4">
          <h2 className="font-display mb-0 mr-4 text-4xl dark">
            {printNumber}.
          </h2>
          <h2 className="mb-0 font-display text-2xl">{printTitle}</h2>
        </div>
        <img src={image} alt={printTitle} />
      </div>
      <div className="portfolio-card card card-back" onClick={flipFront}>
        <div className="card-title flex flex-row items-end p-4">
          <h2 className="font-display mb-0 mr-4 text-4xl dark">
            {printNumber}.
          </h2>
          <h2 className="mb-0 font-display text-2xl">{printTitle}</h2>
        </div>
        <div className="card-inner p-8">
          <h3 className="mb-5 font-display text-xl leading-5 block w-56">
            {printSubtitle}
          </h3>
          <p className="mb-3 text-green-light text-xs">{printText1}</p>
          <p className="mb-3 text-green-light text-xs">{printText2}</p>
          <a
            href={printUrl}
            className="contact-link text-orange pr-5 mt-7 block"
            target="_blank"
            rel="noreferrer">
            <ChevronRightCircle size="48" className="mr-3" />
            <span>View Website</span>
          </a>
        </div>
      </div>
    </CardFlip>
  );
};

export default FlipCard;
