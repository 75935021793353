import * as React from "react";
import { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import "./index.scss";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css/animate.min.css";
import logo from "../img/logo.png";
import { ChevronRightCircle } from "@styled-icons/boxicons-regular/ChevronRightCircle";
import { ChatDots } from "@styled-icons/bootstrap/ChatDots";
import { Menu } from "@styled-icons/feather/Menu";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

import portfolio1 from "../img/portfolio-01.jpg";
import portfolio2 from "../img/portfolio-02.jpg";
import portfolio3 from "../img/portfolio-03.png";
import portfolio4 from "../img/portfolio-04.png";
import portfolio5 from "../img/portfolio-05.png";
import portfolio6 from "../img/portfolio-06.png";
import portfolio7 from "../img/portfolio-07.png";
import { Clockwise } from "@styled-icons/remix-fill";

import { Html5 } from "@styled-icons/boxicons-logos/Html5";
import { Sass } from "@styled-icons/fa-brands/Sass";
import { Wordpress } from "@styled-icons/boxicons-logos/Wordpress";
import { Docker } from "@styled-icons/boxicons-logos/Docker";
import { Javascript } from "@styled-icons/boxicons-logos/Javascript";
import { ReactLogo } from "@styled-icons/boxicons-logos/ReactLogo";
import { Adobephotoshop } from "@styled-icons/simple-icons/Adobephotoshop";
import { Php } from "@styled-icons/boxicons-logos/Php";
import { Phpmyadmin } from "@styled-icons/simple-icons/Phpmyadmin";
import { Git } from "@styled-icons/boxicons-logos/Git";
import { Npm } from "@styled-icons/fa-brands/Npm";

import aboutMe from "../img/about-me.jpg";

import { Twitter } from "@styled-icons/boxicons-logos/Twitter";
import { StackOverflow } from "@styled-icons/boxicons-logos/StackOverflow";
import { Email } from "@styled-icons/evaicons-solid/Email";

// import particlehtml from "img/particle-html.svg";
// import particlenpm from "img/particle-npm.svg";
// import particlereactjs from "img/particle-reactjs.svg";
// import particlesass from "img/particle-sass.svg";
// import particlewp from "img/particle-sass.svg";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";

import FlipCard from "../components/flipCard";

// markup
const IndexPage = () => {
  const particlesInit = async (main) => {
    // console.log(main);

    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(main);
  };

  const particlesLoaded = (container) => {
    // console.log(container);
  };

  var colors = new Array(
    [19, 57, 49],
    [69, 103, 61],
    [19, 83, 70],
    [25, 74, 64],
    [45, 76, 70],
    [29, 86, 69],
    [110, 157, 99]
  );

  var step = 0;
  //color table indices for:
  // current color left
  // next color left
  // current color right
  // next color right
  var colorIndices = [0, 1, 2, 3];

  //transition speed
  var gradientSpeed = 0.003;

  function updateGradient() {
    var c0_0 = colors[colorIndices[0]];
    var c0_1 = colors[colorIndices[1]];
    var c1_0 = colors[colorIndices[2]];
    var c1_1 = colors[colorIndices[3]];

    var istep = 1 - step;
    var r1 = Math.round(istep * c0_0[0] + step * c0_1[0]);
    var g1 = Math.round(istep * c0_0[1] + step * c0_1[1]);
    var b1 = Math.round(istep * c0_0[2] + step * c0_1[2]);
    var color1 = "#" + ((r1 << 16) | (g1 << 8) | b1).toString(16);

    var r2 = Math.round(istep * c1_0[0] + step * c1_1[0]);
    var g2 = Math.round(istep * c1_0[1] + step * c1_1[1]);
    var b2 = Math.round(istep * c1_0[2] + step * c1_1[2]);
    var color2 = "#" + ((r2 << 16) | (g2 << 8) | b2).toString(16);

    step += gradientSpeed;
    if (step >= 1) {
      step %= 1;
      colorIndices[0] = colorIndices[1];
      colorIndices[2] = colorIndices[3];

      //pick two new target color indices
      //do not pick the same as the current one
      colorIndices[1] =
        (colorIndices[1] +
          Math.floor(1 + Math.random() * (colors.length - 1))) %
        colors.length;
      colorIndices[3] =
        (colorIndices[3] +
          Math.floor(1 + Math.random() * (colors.length - 1))) %
        colors.length;
    }
  }

  setInterval(updateGradient, 10);

  useEffect(() => {
    const sections = document.querySelectorAll(".screen");
    const config = {
      rootMargin: "1px",
    };

    let observer = new IntersectionObserver(function (entries) {
      entries.forEach((entry) => {
        // console.log(entry);
        if (entry.isIntersecting) {
          intersectionHandler(entry);
        }
      });
    }, config);

    sections.forEach((section) => {
      observer.observe(section);
    });

    function intersectionHandler(entry) {
      const id = entry.target.id;
      const currentlyActive = document.querySelector(".wrapper .active");
      const shouldBeActive = document.querySelector(
        ".wrapper [data-ref=" + id + "]"
      );

      if (currentlyActive) {
        currentlyActive.classList.remove("active");
      }
      if (shouldBeActive) {
        shouldBeActive.classList.add("active");
      }
    }
  });
  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Tom Green - Web Designer & Front-End Developer</title>
        <meta
          name="description"
          content="Leeds based web designer & front-end developer."
        />
        <link rel="canonical" href="http://tcgreen.me" />
      </Helmet>
      <div className="header-bg">
        <header className="header px-5 md:px-20 lg:px-32 flex items-center">
          <div className="flex flex-row min-w-full">
            <div className="logo-area flex mx-auto lg:mx-0">
              <span>tcg</span>
            </div>
            {/* <div className="menu-toggler flex flex-grow justify-end">
          <Menu size="52" />
        </div> */}
          </div>
        </header>
        <div id="gradient" className="box-container mx-5 md:mx-20 lg:mx-32">
          <div className="box-content">
            <div className="flex flex-col w-100 xl:w-2/3 mx-auto px-6 md:px-20">
              <AnimationOnScroll
                animateIn="animate__fadeIn"
                duration={1}
                delay={1000}
                animateOnce>
                <h1 className="font-display font-light text-2xl xl:text-4xl md:text-justify leading-tight mb-3 md:mb-5 xl:mb-10">
                  Hello there! My name's Tom, a Web Designer and Front-End
                  Developer specialising in custom WordPress websites written
                  with clean and lightweight code. I enjoy creating engaging web
                  sites and web applications with a drive to learn more.
                </h1>
              </AnimationOnScroll>
              <div className="hero-links flex flex-row items-center">
                <AnimationOnScroll
                  animateIn="animate__fadeIn"
                  duration={1}
                  delay={1500}
                  animateOnce>
                  <a
                    href="mailto:tom@tcgreen.me"
                    className="contact-link text-orange text-sm md:text-lg pr-3 md:pr-5">
                    <ChevronRightCircle size="48" className="mr-3" />
                    <span>Get in touch</span>
                  </a>
                  <a
                    href="#about"
                    className="about-link text-green-light text-sm md:text-lg pl-3 md:pl-5">
                    <ChatDots size="42" className="mr-3" />
                    <span>About Me</span>
                  </a>
                </AnimationOnScroll>
              </div>
            </div>
          </div>
          <div className="box">
            <div className="blob blob-1"></div>
            <div className="blob blob-2"></div>
            <div className="blob blob-3"></div>
            <div className="blob blob-4"></div>
            <div className="blob blob-5"></div>
            <div className="blob blob-6"></div>
            <div className="blob blob-7"></div>
            <div className="blob blob-8"></div>
            <div className="blob blob-9"></div>
            <div className="blob blob-10"></div>
            <div className="blob blob-11"></div>
            <div className="blob blob-12"></div>
            <div className="blob blob-13"></div>
            <div className="blob blob-14"></div>
          </div>
        </div>
        <div className="scroll mx-20 lg:mx-32 py-8 flex flex-row items-center">
          <span className="scroll-icon mr-5">
            <span className="scroll-icon__dot"></span>
          </span>
          <span className="text-lg">Recent Projects</span>
        </div>
      </div>
      <div className="lg:hidden portfolio-mob w-100 relative bg-projects py-10">
        <Swiper
          loop={true}
          spaceBetween={20}
          slidesPerView={1.2}
          breakpoints={{
            // when window width is >= 640px
            640: {
              width: 640,
              slidesPerView: 1.8,
            },
            // when window width is >= 768px
            768: {
              width: 768,
              slidesPerView: 2.2,
            },
          }}>
          <SwiperSlide>
            <FlipCard
              number="1"
              title="Electra EV"
              image={portfolio5}
              subtitle="WordPress + WPML development for a multilingual website."
              text1="I was tasked with building a new multilingual website for the new Electra EV website to showcase the clients' portfolio of vehicles and services."
              text2="Building a custom WordPress theme compatible with WPML, we achieved our goals of rebranding and expanding to new audiencies in France and Germany."
              url="https://electracommercialvehicles.com/"
            />
          </SwiperSlide>
          <SwiperSlide>
            <FlipCard
              number="2"
              title="Compresores Redin"
              image={portfolio3}
              subtitle="Re-design and development."
              text1="Updating the website with a fresh look while maintaining their original branding, we worked together to design and develop a new responsive website with WordPress & WooCommerce."
              text2="Dealing in industrial air compressors, the website needed to act as a catalog and centre of information to the equipment and maintenance offered for Ingersoll Rand's exclusive distrubutor in the Euskadi and Navarra area."
              url="https://compresoresredin.com/"
            />
          </SwiperSlide>
          <SwiperSlide>
            <FlipCard
              number="3"
              title="Metakats"
              image={portfolio4}
              subtitle="Front-end development with ReactJS and Bootstrap."
              text1="My role was to develop a mobile-friendly React website from the beautiful design provided for an ambitious and ever-evolving Unreal Engine NFT collection."
              text2="Phase 2 coming soon, with the Printful-integrated WooCommerce store under development."
              url="https://compresoresredin.com/"
            />
          </SwiperSlide>
          <SwiperSlide>
            <FlipCard
              number="4"
              title="PWSS"
              image={portfolio6}
              subtitle="Re-development for a new look for Perth Waterproofing & Silicone Solutions."
              text1="Working with a talented designer, we created a new lightweight wordpress website to merge the two sets of services offered under one flagship website."
              url="http://perthwss.com.au/"
            />
          </SwiperSlide>
          <SwiperSlide>
            <FlipCard
              number="5"
              title="CTS"
              image={portfolio7}
              subtitle="Custom WordPress development for Complete Transport Solutions"
              text1="My role was to develop a responsive and lightweight WordPress website for CTS to list their services in the transportation sector."
              url="https://www.ctstraffic.co.uk//"
            />
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="container-fluid xl:px-32 lg:py-10 mx-auto bg-projects hidden lg:block">
        <div className="wrapper">
          <div className="active image-wrapper w-1/2" data-ref="first-screen">
            <img src={portfolio5} alt="Electra EV" />
          </div>
          <div className="image-wrapper w-1/2" data-ref="second-screen">
            <img src={portfolio3} alt="Redin Compresores" />
          </div>
          <div className="image-wrapper w-1/2" data-ref="third-screen">
            <img src={portfolio4} alt="Compresores Redin" />
          </div>
          <div className="image-wrapper w-1/2" data-ref="fourth-screen">
            <img src={portfolio6} alt="PWSS" />
          </div>
          <div className="image-wrapper w-1/2" data-ref="fifth-screen">
            <img src={portfolio7} alt="CTS" />
          </div>
          <div id="first-screen" className="screen md:w-1/2 lg:px-20">
            <div className="screen-content flex flex-col justify-center xl:pr-32">
              <h2 className="font-display mb-5 lg:mb-7 text-4xl dark">1.</h2>
              <h2 className="mb-5 font-display text-4xl">
                Electra Commercial Vehicles
              </h2>
              <h3 className="mb-5 font-display text-xl">
                WordPress + WPML development for multilingual website.
              </h3>
              <p className="mb-3 text-green-light">
                I was tasked with building a new multilingual website for the
                new Electra EV website to showcase the clients' portfolio of
                vehicles and services.
              </p>
              <p className="mb-5 text-green-light">
                After receiving the design, I developed the new look to a custom
                WordPress theme compatible with WPML. We achieved the clients'
                goals of rebranding and expanding to new audiencies in France
                and Germany.
              </p>
              <a
                href="https://www.electracommercialvehicles.com/"
                className="contact-link text-orange text-xl pr-5"
                target="_blank"
                rel="noreferrer">
                <ChevronRightCircle size="48" className="mr-3" />
                <span>View Website</span>
              </a>
            </div>
          </div>
          <div id="second-screen" className="screen md:w-1/2 lg:px-20">
            <div className="screen-content flex flex-col justify-center xl:pr-32">
              <h2 className="font-display mb-5 lg:mb-7 text-4xl dark">2.</h2>
              <h2 className="mb-5 font-display text-4xl">Compresores Redín</h2>
              <h3 className="mb-5 font-display text-xl">
                Re-design and development.
              </h3>
              <p className="mb-3 text-green-light">
                Updating the website with a fresh look while maintaining their
                original branding, we worked together to design and develop a
                new responsive website with WordPress &amp; WooCommerce.
              </p>
              <p className="text-green-light mb-5">
                Dealing in industrial air compressors, the website needed to act
                as a catalog and centre of information to the equipment and
                maintenance offered for Ingersoll Rand's exclusive distrubutor
                in the Euskadi and Navarra area.
              </p>
              <a
                href="https://compresoresredin.com/"
                className="contact-link text-orange text-xl pr-5"
                target="_blank"
                rel="noreferrer">
                <ChevronRightCircle size="48" className="mr-3" />
                <span>View Website</span>
              </a>
            </div>
          </div>
          <div id="third-screen" className="screen md:w-1/2 lg:px-20">
            <div className="screen-content flex flex-col justify-center xl:pr-32">
              <h2 className="font-display mb-5 lg:mb-7 text-4xl dark">3.</h2>
              <h2 className="mb-5 font-display text-4xl">MetaKats</h2>
              <h3 className="mb-5 font-display text-xl">
                Front-end development with ReactJS and Bootstrap.
              </h3>
              <p className="mb-3 text-green-light">
                My role was to develop a mobile-friendly React website from the
                beautiful design provided for an ambitious and ever-evolving
                Unreal Engine NFT collection.
              </p>
              <p className="mb-5 text-green-light">
                Phase 2 coming soon, with the Printful-integrated WooCommerce
                store under development.
              </p>
              <a
                href="https://metakats.io"
                className="contact-link text-orange text-xl pr-5"
                target="_blank"
                rel="noreferrer">
                <ChevronRightCircle size="48" className="mr-3" />
                <span>View Website</span>
              </a>
            </div>
          </div>
          <div id="fourth-screen" className="screen md:w-1/2 lg:px-20">
            <div className="screen-content flex flex-col justify-center xl:pr-32">
              <h2 className="font-display mb-5 lg:mb-7 text-4xl dark">4.</h2>
              <h2 className="mb-5 font-display text-4xl">PWSS</h2>
              <h3 className="mb-5 font-display text-xl">
                Re-development for a new look for Perth Waterproofing & Silicone
                Solutions.
              </h3>
              <p className="mb-3 text-green-light">
                Working with a talented designer, we created a new lightweight
                wordpress website to merge the two sets of services offered
                under one flagship website.
              </p>
              <a
                href="https://www.perthwss.com.au/"
                className="contact-link text-orange text-xl pr-5"
                target="_blank"
                rel="noreferrer">
                <ChevronRightCircle size="48" className="mr-3" />
                <span>View Website</span>
              </a>
            </div>
          </div>
          <div id="fifth-screen" className="screen md:w-1/2 lg:px-20">
            <div className="screen-content flex flex-col justify-center xl:pr-32">
              <h2 className="font-display mb-5 lg:mb-7 text-4xl dark">5.</h2>
              <h2 className="mb-5 font-display text-4xl">CTS</h2>
              <h3 className="mb-5 font-display text-xl">
                Custom WordPress development for Complete Transport Solutions.
              </h3>
              <p className="mb-3 text-green-light">
                My role was to develop a responsive and lightweight WordPress
                website for CTS, from the design provided, to showcase their
                services in the transportation sector.
              </p>
              <p className="mb-5 text-green-light">
                With WordPress, Bootstrap and Underscores, we built a new custom
                website meeting the clients' needs.
              </p>
              <a
                href="https://www.ctstraffic.co.uk/"
                className="contact-link text-orange text-xl pr-5"
                target="_blank"
                rel="noreferrer">
                <ChevronRightCircle size="48" className="mr-3" />
                <span>View Website</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div id="about" className="about-skills">
        <div
          id="gradient2"
          className="box-container mx-5 lg:mx-10 border-bottom">
          <div className="box-content">
            <div className="flex flex-col w-100 lg:w-2/3 mx-auto px-10 md:px-20 mx:px-32">
              <AnimationOnScroll
                animateIn="animate__fadeIn"
                duration={1}
                delay={500}
                animateOnce>
                <h2 className="font-display font-light text-2xl md:text-4xl text-justify leading-tight mb-3 md:mb-5">
                  I'd love to talk more about what I've been working on and how
                  I can help you. In the meantime, view a breakdown of my skills
                  &amp; proficiencies below. Think I might be a good fit? Don’t
                  hesitate to{" "}
                  <a href="mailto:tom@tcgreen.me" className="text-orange">
                    get in touch
                  </a>
                  .
                </h2>
              </AnimationOnScroll>
            </div>
          </div>
          <div className="box skills-box">
            <Particles
              id="tsparticles"
              init={particlesInit}
              loaded={particlesLoaded}
              options={{
                fullScreen: false,
                fpsLimit: 60,
                interactivity: {
                  events: {
                    onHover: {
                      enable: true,
                      mode: "attract",
                      parallax: {
                        enable: false,
                        force: 100,
                        smooth: 10,
                      },
                    },
                    resize: true,
                  },
                },
                particles: {
                  number: {
                    limit: 15,
                  },
                  links: {
                    enable: false,
                  },
                  rotate: {
                    value: 0,
                    random: true,
                    direction: Clockwise,
                    animation: {
                      enable: true,
                      speed: 5,
                      sync: false,
                    },
                  },
                  move: {
                    enable: true,
                    speed: 0.5,
                    outModes: {
                      default: "out",
                      bottom: "out",
                      top: "out",
                      left: "out",
                      right: "out",
                    },
                  },
                  size: {
                    value: 20,
                  },
                  shape: {
                    type: "images",
                    image: [
                      {
                        src: "img/particle-html.svg",
                        width: 20,
                        height: 20,
                      },
                      {
                        src: "img/particle-npm.svg",
                        width: 20,
                        height: 20,
                      },
                      {
                        src: "img/particle-reactjs.svg",
                        width: 20,
                        height: 20,
                      },
                      {
                        src: "img/particle-sass.svg",
                        width: 20,
                        height: 20,
                      },
                      {
                        src: "img/particle-wordpress.svg",
                        width: 20,
                        height: 20,
                      },
                    ],
                  },
                },
              }}
            />
            <div className="blob blob-1"></div>
            <div className="blob blob-2"></div>
            <div className="blob blob-3"></div>
            <div className="blob blob-4"></div>
            <div className="blob blob-5"></div>
            <div className="blob blob-6"></div>
            <div className="blob blob-7"></div>
            <div className="blob blob-8"></div>
            <div className="blob blob-9"></div>
            <div className="blob blob-10"></div>
            <div className="blob blob-11"></div>
          </div>
        </div>
      </div>
      <div className="skills-timeline mx-5 md:mx-20 lg:mx-32">
        <VerticalTimeline lineColor={"#133931"}>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: "#133931", color: "#fff" }}
            contentArrowStyle={{ borderRight: "7px solid #133931" }}
            iconStyle={{ background: "#4d7850", color: "#fff" }}
            icon={<Html5 />}>
            <h3 className="font-display font-light text-xl lg:text-3xl mb-3">
              HTML/CSS
            </h3>
            <h4 className="text-xs text-green-light uppercase">
              Full proficiency
            </h4>
            <p>
              Modern standard of HTML/CSS following best practices. Experience
              building and styling websites and components with a mobile-first
              approach, using frameworks such as Bootstrap for a quick build, or
              Tailwindcss for lightweight code.
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: "#133931", color: "#fff" }}
            contentArrowStyle={{ borderRight: "7px solid #133931" }}
            iconStyle={{ background: "#4d7850", color: "#fff" }}
            icon={<Sass />}>
            <h3 className="font-display font-light text-xl lg:text-3xl mb-3">
              Sass
            </h3>
            <h4 className="text-xs text-green-light uppercase">
              Full proficiency
            </h4>
            <p>Experienced in writing SCSS and postcss.</p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: "#133931", color: "#fff" }}
            contentArrowStyle={{ borderRight: "7px solid #133931" }}
            iconStyle={{ background: "#4d7850", color: "#fff" }}
            icon={<Npm />}>
            <h3 className="font-display font-light text-xl lg:text-3xl mb-3">
              NPM
            </h3>
            <h4 className="text-xs text-green-light uppercase">
              Professional working proficiency
            </h4>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: "#133931", color: "#fff" }}
            contentArrowStyle={{ borderRight: "7px solid #133931" }}
            iconStyle={{ background: "#4d7850", color: "#fff" }}
            icon={<Wordpress />}>
            <h3 className="font-display font-light text-xl lg:text-3xl mb-3">
              WordPress
            </h3>
            <h4 className="text-xs text-green-light uppercase">
              Full proficiency
            </h4>
            <p>
              10 years experience developing custom WordPress themes. A
              preference for Understrap/Underscores with Advanced Custom Fields
              as my workflow, making use of ACF Pro's Flexible Content Fields to
              build customised themes with easily manageable content.
            </p>
            <p className="hidden lg:inline-block">
              Experience with gui builders ie. Elementor and WPBakery,
              developing custom components for such builders.
            </p>
            <p className="hidden lg:inline-block">
              Developing custom post types, archive pages, widgets, etc.
            </p>
            <p className="hidden lg:inline-block">
              Installing, configuring, and debugging plugins.
            </p>
            <p className="hidden lg:inline-block">
              Building themes with WPML compatibility and managing translations.
            </p>
            <p className="hidden lg:inline-block">
              Managing and developing WooCommerce stores of all shapes and
              sizes.
            </p>
            <p className="hidden lg:inline-block">
              A little experience with REST API - would love to learn more!
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: "#133931", color: "#fff" }}
            contentArrowStyle={{ borderRight: "7px solid #133931" }}
            iconStyle={{ background: "#4d7850", color: "#fff" }}
            icon={<Javascript />}>
            <h3 className="font-display font-light text-xl lg:text-3xl mb-3">
              Javascript
            </h3>
            <h4 className="text-xs text-green-light uppercase">
              Professional working proficiency
            </h4>
            <p>
              Experience writing Javascript and jQuery, leveraging libraries and
              configuring scripts to create brilliant user experiences.
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: "#133931", color: "#fff" }}
            contentArrowStyle={{ borderRight: "7px solid #133931" }}
            iconStyle={{ background: "#4d7850", color: "#fff" }}
            icon={<Git />}>
            <h3 className="font-display font-light text-xl lg:text-3xl mb-3">
              Git
            </h3>
            <h4 className="text-xs text-green-light uppercase">
              Professional working proficiency
            </h4>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: "#133931", color: "#fff" }}
            contentArrowStyle={{ borderRight: "7px solid #133931" }}
            iconStyle={{ background: "#4d7850", color: "#fff" }}
            icon={<Phpmyadmin />}>
            <h3 className="font-display font-light text-xl lg:text-3xl mb-3">
              PhpMyAdmin
            </h3>
            <h4 className="text-xs text-green-light uppercase">
              Professional working proficiency
            </h4>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: "#133931", color: "#fff" }}
            contentArrowStyle={{ borderRight: "7px solid #133931" }}
            iconStyle={{ background: "#4d7850", color: "#fff" }}
            icon={<Php />}>
            <h3 className="font-display font-light text-xl lg:text-3xl mb-3">
              PHP
            </h3>
            <h4 className="text-xs text-green-light uppercase">
              Good working proficiency
            </h4>
            <p>
              PHP knowledge mostly centered around WordPress theme development.
              Creating custom post types, writing custom loops and custom
              templates to integrate with Advanced Custom Fields.
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: "#133931", color: "#fff" }}
            contentArrowStyle={{ borderRight: "7px solid #133931" }}
            iconStyle={{ background: "#4d7850", color: "#fff" }}
            icon={<ReactLogo />}>
            <h3 className="font-display font-light text-xl lg:text-3xl mb-3">
              ReactJS
            </h3>
            <h4 className="text-xs text-green-light uppercase">
              Limited working proficiency
            </h4>
            <p>
              Developing slick front-end web apps using React and Gatsby.
              Learning more every day!
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            iconStyle={{ background: "#4d7850", color: "#fff" }}
            contentStyle={{ background: "#133931", color: "#fff" }}
            contentArrowStyle={{ borderRight: "7px solid #133931" }}
            icon={<Adobephotoshop />}>
            <h3 className="font-display font-light text-xl lg:text-3xl mb-3">
              Adobe Creative Suite
            </h3>
            <h4 className="text-xs text-green-light uppercase">
              Full proficiency
            </h4>
            <p>Experience designing websites and pages using Photoshop.</p>
            <p>
              Exporting assets and converting designs from Photoshop, XD, and
              Illustrator to fully functioning websites, with a perfectionist
              attitude to matching the design pixel-perfect.
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            iconStyle={{ background: "#4d7850", color: "#fff" }}
            contentStyle={{ background: "#133931", color: "#fff" }}
            contentArrowStyle={{ borderRight: "7px solid #133931" }}
            icon={<Docker />}>
            <h3 className="font-display font-light text-xl lg:text-3xl mb-3">
              Docker
            </h3>
            <h4 className="text-xs text-green-light uppercase">
              Professional working proficiency
            </h4>
          </VerticalTimelineElement>
        </VerticalTimeline>
      </div>
      <div className="about-me-wrapper">
        <div className="container mx-auto flex flex-col py-16 lg:py-44 lg:px-20 xl:px-32">
          <div className="about-me w-12/12 lg:w-10/12 flex flex-col md:flex-row mx-auto px-10">
            <div className="about-photo-wrapper md:pr-10">
              <AnimationOnScroll
                animateIn="animate__fadeIn"
                duration={1}
                animateOnce>
                <img
                  className="about-photo mb-5"
                  src={aboutMe}
                  alt="Tom Green"
                />
              </AnimationOnScroll>
            </div>
            <div className="about-content text-justify text-sm lg:text-base">
              <AnimationOnScroll
                animateIn="animate__fadeInUp"
                duration={1}
                animateOnce>
                <h3 className="font-display text-4xl mb-5 text-green-light">
                  Hello! I'm Tom.
                </h3>
              </AnimationOnScroll>
              <AnimationOnScroll
                animateIn="animate__fadeInUp"
                duration={1}
                delay={100}
                animateOnce>
                <p className="mb-3 text-base lg:text-lg">
                  I'm a freelance web designer &amp; front-end developer
                  originally from Hull, but I have spent the last ten years of
                  my life in southern Spain.
                </p>
              </AnimationOnScroll>
              <AnimationOnScroll
                animateIn="animate__fadeInUp"
                duration={1}
                delay={200}
                animateOnce>
                <p className="mb-3">
                  I moved back to the UK in July to be closer to family and am
                  currently searching for a fulltime position around Leeds or
                  nearby. My specialty is in building responsive WordPress
                  websites and coding the front-end of web applications. I would
                  love to find a positon that would expose me to new
                  opportunities, technologies, and an environment to learn more.
                </p>
              </AnimationOnScroll>
              <AnimationOnScroll
                animateIn="animate__fadeInUp"
                duration={1}
                delay={300}
                animateOnce>
                <p>
                  If you are looking to grow your team and think I may be a good
                  fit,{" "}
                  <a className="text-orange" href="/">
                    contact me
                  </a>{" "}
                  and let's chat!
                </p>
              </AnimationOnScroll>
              <div className="socials flex flex-row mt-10">
                <div className="social">
                  <a
                    href="https://x.com/tcgreen20"
                    target="_blank"
                    rel="noreferrer">
                    <Twitter size="30" />
                  </a>
                </div>
                <div className="social">
                  <a
                    href="https://stackoverflow.com/users/5078278/tom-green"
                    target="_blank"
                    rel="noreferrer">
                    <StackOverflow size="30" />
                  </a>
                </div>
                <div className="social">
                  <a href="mailto:tom@tcgreen.me">
                    <Email size="30" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default IndexPage;
